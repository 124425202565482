.textInput>input {
  -webkit-appearance: none;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--blue-grey-lighten-3);
  box-sizing: border-box;
  display: inline-grid;
  font-size: 18px;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: center;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 19px 16px 5px;
  position: relative;
  -webkit-transition: box-shadow 0.2s linear, border 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s linear, border 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  vertical-align: middle;
  width: 100%;
}

.textInput>input {
  /* background-color: rgba(0, 0, 0, 0.05); */
  color: var(--primary-text);
}

/* .textInput:not(.disabled)>input:hover,
.textInput:not(.disabled)>input:active,
.textInput.active>input {
  background-color: rgba(0, 0, 0, 0.06);
} */

/* 
.textInput.active>input {
  box-shadow: 0px 0px 0px 1px var(--blue-grey);
} */

.textInput.disabled>input {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

/***************************************/

.textInput>label {
  color: var(--blue-grey-darken-2);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  pointer-events: none;
  position: absolute;
  transform-origin: top left;
  transform: translate(16px, 15px);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  z-index: 2;
}

.textInput.active>label,
.textInput.filled>label {
  transform: translate(16px, 4px) scale(0.75);
}

.textInput.disabled>label {
  pointer-events: none;
}