/* Links */

.links {
  padding: 2px 12px 0;
  background-color: white;
  overflow-x: auto;
}

.links::-webkit-scrollbar {
  display: none;
}

.links>div {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  place-content: start;
  gap: 12px;
  width: max-content;
  margin: auto;
}

.links>div>a {
  background-color: var(--blue-grey-lighten-5);
  background-color: #d3e3fd;
  color: #37474F !important;
  border-radius: 24px;
  display: block;
  padding: 0 16px;
}

.links>div>a>span {
  color: #37474F !important;
  line-height: 48px;
  font-weight: 600;
}

/* Search modal */

.searchModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 10;
}

.searchModalOverlay.active {
  display: block;
}

.searchModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  margin: auto;
  background-color: var(--floral-white);
  border-radius: 0 0 24px 24px;
  max-width: 600px;
  z-index: 11;
}

.searchModal.active {
  display: block;
}

.searchModal>.searchModalBody {
  display: grid;
  align-content: start;
  grid-template-columns: minmax(0, 1fr);
  gap: 20px;
  padding: 20px 12px 50px;
}

.searchModal>.searchModalBody>.title {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

/* Annunci */

.wrapperAnnunci {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  gap: 10px;
}

.anteprimaAnnuncio>.imageContainer,
.anteprimaAnnuncio>span {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #efefef;
  background: linear-gradient(90deg,
      hsl(210, 15%, 88%),
      hsl(210, 15%, 95%),
      hsl(210, 15%, 88%));
  background-size: 200%;
  animation: loader 1s infinite reverse;
}

@keyframes loader {
  from {
    background-position: -100% 0;
  }

  to {
    background-position: 100% 0;
  }
}