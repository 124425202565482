/* Categorie list */

.wrapperCategorie {
  margin-top: 10px;
  display: grid;
}

.wrapperCategorie>div {
  padding: 20px 12px;
  border-bottom: 1px solid #ccc;
}

.wrapperCategorie>div:first-child {
  border-top: 1px solid #ccc;
}

/* Annunci */

.wrapperAnnunci {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  /* gap: 20px 12px; */
  gap: 10px;
}

.wrapperAnnunci>span {
  grid-column: span 2;
  font-weight: 600;
}