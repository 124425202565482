.credits {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 40px;
  font-weight: 600;
}

.option {
  border: 1px solid var(--blue-grey-lighten-3);
  padding: 12px 16px;
  gap: 16px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  transition: all 0.3s ease;
}

.option.selected {
  border-color: #1a73e8;
  background-color: rgba(26, 115, 232, 0.1);
}