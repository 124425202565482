.checkboxWrapper {
  display: grid;
  place-items: center;
}

.checkboxWrapper>input {
  display: none;
}

.checkboxWrapper.disabled>span {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}
