.button {
  -webkit-appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-grid;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  gap: 16px;
  grid-auto-flow: column;
  height: 48px;
  justify-content: center;
  /* margin: auto; */
  outline: none;
  overflow: hidden;
  padding: 0 28px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  -webkit-transition: box-shadow 0.08s linear, min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.08s linear, min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  letter-spacing: 0.5px;
}

.button:hover {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  /* background-color: var(--desert-gold); */
}

.button:active {
  box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
}

.button.iconOnly {
  padding: 0 9px;
}

.button.fullWidth {
  width: 100%;
}

.button.disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.button.primary {
  background-color: #FF9100;
  color: black;
  background-color: #1a73e8;
  color: white;
}

.button.secondary {
  background-color: #C3DBFF;
  background-color: var(--blue-grey-lighten-5);
  background-color: hsl(204, 15%, 90%);
  /* color: #37474F; */
  color: black;
}

/* .button.small {
  height: 48px;
  padding: 0 16px;
  gap: 8px;
  border-radius: 24px;
  font-size: 1rem;
}
.button.iconOnly.small {
  padding: 0 12px;
} */

.button.mini {
  padding: 0 8px;
  height: 26px;
  font-size: 13px;
  border-radius: 4px;
  gap: 10px;
}