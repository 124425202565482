.wrapper {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 10px 0;
}

.wrapper>span:nth-child(odd) {
  font-size: 16px;
  font-weight: 500;
}

.wrapper>span:nth-child(even) {
  font-size: 16px;
}