.topBar {
  /* position: fixed; */
  top: 0;
  width: 100%;
  max-width: 900px;
  /* left: 0;
  right: 0; */
  padding: 0 12px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 0;
  /* color: var(--floral-white);
  background-color: var(--coarse-wool); */
  color: black;
  background-color: white;
}

.topBar>* {
  height: 50px;
  display: grid;
  grid-gap: 20px;
  align-content: center;
}

.topBar>.topBarLeft {
  grid-gap: 10px;
  grid-auto-flow: column;
  justify-content: start;
}

.topBar>.topBarCenter {
  grid-auto-flow: column;
  justify-content: center;
}

.topBar>.topBarRight {
  grid-auto-flow: column;
  justify-content: end;
}

.topBar a {
  display: grid;
}

.topBar .logo path {
  /* fill: var(--floral-white); */
  fill: black !important;
}

/* .topBar > .topBarSub {
  height: unset;
  grid-column: span 3;
  padding-bottom: 20px;
}

.topBar > .topBarSub:empty {
  padding-bottom: 0;
}

.topBar > .topBarSub > .hero {
  padding: 20px 0 16px;
  font-size: 26px;
  line-height: 32px;
}

.topBar > .topBarSub > .logged {
  font-size: 16px;
} */

.credits {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
}