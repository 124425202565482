@import-normalize;
/* bring in normalize.css styles */

:root {
  --blue-grey-lighten-5: #ECEFF1;
  --blue-grey-lighten-4: #cfd8dc;
  --blue-grey-lighten-3: #b0bec5;
  --blue-grey: #607d8b;
  --blue-grey-darken-1: #78909c;
  --blue-grey-darken-2: #455a64;
  --blue-grey-darken-3: #37474f;
  --blue-grey-darken-4: #263238;
  --primary-text: #050505;
  --font-family: "Gabarito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectable {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

body,
input,
select,
button {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  /* background-color: var(--blue-grey-lighten-5); */
  /* background-color: hsl(204, 15%, 96%); */
}

body.block_scroll {
  overflow: hidden;
}

span,
label,
p {
  word-break: break-word;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.row {
  width: 100%;
  margin: 0 0 16px;
}

.col {
  margin: 0 16px;
}

.col-flex,
.col-flex-between,
.col-flex-center,
.col-flex-end {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* width: fit-content; */
}

.col-flex-between {
  justify-content: space-between;
}

.col-flex-center {
  justify-content: center;
}

.col-flex-end {
  justify-content: flex-end;
}

.page-title,
.page-section {
  display: block;
  font-family: "Young Serif";
}

.page-title {
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  color: var(--primary-text)
}

.page-section {
  font-size: 15px;
  line-height: 18px;
  color: var(--blue-grey-darken-3)
}