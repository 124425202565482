.tableAnnunci {
  display: grid;
  grid-template-columns: auto repeat(1, 1fr);
  gap: 15px 10px;
  align-items: center;
  /* border: 1px solid var(--blue-grey-lighten-3);
  border-radius: 8px;
  padding: 4px; */
}

.tableAnnunci>span>span {
  display: block;
}

.tableAnnunci>.divider {
  grid-column: 1 / 5;
}

.tableAnnunci>.divider {
  border-bottom: 1px solid var(--blue-grey-lighten-4);
}

.tableAnnunci .azioniAnnuncio {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
}