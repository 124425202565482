.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 90;
  display: grid;
  overflow-y: auto;
  padding: 50px 0;
}

.modal {
  margin: auto;
  background-color: white;
  z-index: 90;
  display: grid;
  justify-items: center;
  border-radius: 20px;
  width: 95%;
  max-width: 700px;
  height: fit-content;
}

.modal>div {
  width: 100%;
}

.modalTitle {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 5px;
  align-items: center;
  padding-left: 12px;
}

.modalTitle>div {
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.modalTitle>span {
  font-size: 18px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-text);
  font-family: "Young Serif";
}

.modalBody {
  display: grid;
  align-content: start;
}