/* Wrapper */

.wrapper {
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--blue-grey-lighten-3);
  box-sizing: border-box;
  display: inline-grid;
  width: 100%;
  padding: 6px;
  gap: 10px;
}

.wrapper.disabled {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.wrapper>label {
  color: var(--blue-grey-darken-2);
  font-size: 14px;
  font-weight: 500;
  pointer-events: none;
  z-index: 2;
  padding: 0 8px;
}

/* Images previews */

.previewsWrapper {
  --image-preview-width: 150px;
  display: grid;
  grid-template-columns: repeat(auto-fill,
      minmax(var(--image-preview-width), 1fr));
  padding: 10px;
  gap: 10px;
  border-radius: 6px;
}

.previewsWrapper:empty {
  display: none;
}

.previewsWrapper>div {
  position: relative;
  border-radius: 8px;
  display: grid;
  place-items: center;
}

.previewsWrapper>div>img {
  border-radius: 3px;
  width: var(--image-preview-width);
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.previewsWrapper>div>.closeIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
  border-radius: 8px;
  color: #d50000;
  background-color: #ffcdd2;
}

.loading {
  width: var(--image-preview-width);
  aspect-ratio: 1 / 1;
  border-radius: 3px;
  background-color: #efefef;
  background: linear-gradient(90deg,
      hsl(210, 15%, 88%),
      hsl(210, 15%, 95%),
      hsl(210, 15%, 88%));
  background-size: 200%;
  animation: loader 1s infinite reverse;
}

@keyframes loader {
  from {
    background-position: -100% 0;
  }

  to {
    background-position: 100% 0;
  }
}