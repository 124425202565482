.snackbar {
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 70px;
  z-index: 110;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow: hidden;
}

.snackbar>span {
  background-color: var(--blue-grey-darken-4);
  color: var(--blue-grey-lighten-5);
  padding: 12px 16px;
  max-width: clamp(100px, 100%, 900px);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;
  animation: 0.3s ease-out 0s 1 slideInFromBottom;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%);
}

.snackbar.hide>span {
  animation: 0.3s ease-out 0s forwards slideOutFromBottom;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(110%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutFromBottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(110%);
  }
}