.statoWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 4px 10px;
  font-size: 14px;
}

.statoWrapper>* {
  grid-column: 2;
}

.statoWrapper>*:first-child {
  grid-column: 1;
  grid-row: span 4;
}

.statoWrapper>.icon {
  width: 40px !important;
  height: 40px !important;
  padding: 7px;
  border: 1px solid transparent;
  border-radius: 50%;
}

.statoWrapper>.icon.warning {
  background-color: #fff3e0;
  border-color: #ffcc80;
  color: #e65100;
}

.statoWrapper>.icon.error {
  background-color: #ffb4ab;
  border-color: #ef9a9a;
  color: #690005;
}

.statoWrapper>.icon.success {
  background-color: #e8f5e9;
  border-color: #c8e6c9;
  color: #1b5e20;
}

.statoWrapper>*:nth-child(2),
.statoWrapper>*:last-child {
  font-size: 16px;
}