.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 100;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow: auto;
}

.dialog {
  width: 90%;
  max-width: 600px;
  background-color: white;
  border-radius: 24px;
  display: grid;
  align-content: start;
  gap: 20px;
  padding: 20px 24px;
  margin: 5vh 0 20vh;
}

.dialog>.title {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 22px;
}

.dialog>.body {
  white-space: pre-wrap;
  font-size: 18px;
}

.dialog>.actions {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  min-width: max-content;
}