/* Annunci */

.wrapperAnnunci {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); */
  gap: 12px;
}

.actions {
  grid-column: span 2;
  display: grid;
  grid-auto-flow: column;
  /* justify-content: space-around; */
  align-items: center;
  justify-content: space-between;
}

.actions .status {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  align-items: center;
  padding: 4px 6px;
  border-radius: 10px;
  font-weight: 300;
  font-size: 14px;
  background-color: #eceff1;
  border: 1px solid #b0bec5;
  color: #263238;
}

.actions .status.warning {
  background-color: #fff3e0;
  border-color: #ffcc80;
  color: #e65100;
}

.actions .status.error {
  background-color: #ffb4ab;
  border-color: #ef9a9a;
  color: #690005;
}

.actions .status.success {
  background-color: #e8f5e9;
  border-color: #c8e6c9;
  color: #1b5e20;
}