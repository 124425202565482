.page {
  min-height: 100vh;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.body {
  width: 100%;
  display: grid;
  align-content: start;
  min-height: calc(100vh - 160px);
  /* grid-template-columns: minmax(0, 1fr); */
  /* padding: 20px 12px 50px; */
  /* gap: 20px; */
  /* margin-top: 50px; */
}
