.inlineAlert {
  padding: 12px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}

.inlineAlert > .text {
  align-self: center;
  font-size: 16px;
  font-weight: 400;
  white-space: pre-wrap;
}

.inlineAlert.error {
  background-color: #ffb4ab;
  border: 1px solid #ef9a9a;
  color: #690005;
}

.inlineAlert.success {
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  color: #1b5e20;
}
