/* Annuncio preview */

.annuncioPreview {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 15px 10px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--blue-grey-lighten-3);
  box-sizing: border-box;
  padding: 8px;
}

.annuncioPreview .imageContainer {
  display: grid;
  place-content: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
  font-size: 12px;
  /* cursor: pointer; */
  margin: auto;
}

.annuncioPreview .imageContainer>img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.annuncioPreview .imageContainer>.imagePlaceholderIcon {
  color: var(--raisin-black);
  opacity: 0.6;
}

.annuncioPreview .mainInfo {
  display: grid;
  align-content: space-evenly;
  gap: 2px;
}

.annuncioPreview .mainInfo>span:not(.description) {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* color: #000; */
  /* font-weight: 400; */
  /* padding: 0 4px; */
}

.annuncioPreview .mainInfo>.title {
  font-weight: 600;
}

.annuncioPreview .mainInfo>.description {
  max-height: 54px;
  overflow: hidden;
  line-height: 18px;
}

.annuncioPreview .mainInfo>.extra {
  font-size: 13px;
  line-height: 17px;
  color: #616161;
}

.annuncioPreview .children {
  grid-column: span 2;
  display: grid;
  grid-auto-flow: column;
  /* justify-content: space-around; */
  align-items: center;
  justify-content: space-between;
}

.annuncioPreview.loading .imageContainer,
.annuncioPreview.loading .mainInfo>span {
  background-color: #efefef;
  background: linear-gradient(90deg,
      hsl(210, 15%, 88%),
      hsl(210, 15%, 95%),
      hsl(210, 15%, 88%));
  background-size: 200%;
  animation: loader 1s infinite reverse;
}

.annuncioPreview.loading .imageContainer {
  width: 100%;
  height: 100%;
}

.annuncioPreview.loading .mainInfo>span {
  width: 100%;
  height: 18px;
  border-radius: 3px;
}

@keyframes loader {
  from {
    background-position: -100% 0;
  }

  to {
    background-position: 100% 0;
  }
}

/* COLORI */

.annuncioPreview .imageContainer.purple {
  background-color: #f3e5f5;
}

.annuncioPreview .imageContainer.purple>.imagePlaceholderIcon {
  color: #ab47bc;
  opacity: 1;
}

.annuncioPreview .imageContainer.blue {
  background-color: #e3f2fd;
}

.annuncioPreview .imageContainer.blue>.imagePlaceholderIcon {
  color: #42a5f5;
  opacity: 1;
}

.annuncioPreview .imageContainer.green {
  background-color: #e8f5e9;
}

.annuncioPreview .imageContainer.green>.imagePlaceholderIcon {
  color: #66bb6a;
  opacity: 1;
}

.annuncioPreview .imageContainer.amber {
  background-color: #fff3e0;
}

.annuncioPreview .imageContainer.amber>.imagePlaceholderIcon {
  color: #ffa726;
  opacity: 1;
}