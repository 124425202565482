.badge {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 4px;
  padding: 4px 10px 4px 6px;
  border-radius: 10px;
  font-weight: 300;
  font-size: 16px;
  background-color: #eceff1;
  /* border: 1px solid #b0bec5; */
  color: #263238;
  justify-content: start;
  width: fit-content;
}

.badge.warning {
  background-color: #fff3e0;
  /* border-color: #ffcc80; */
  color: #e65100;
}

.badge.error {
  background-color: #ffb4ab;
  /* border-color: #ef9a9a; */
  color: #690005;
}

.badge.success {
  background-color: #e8f5e9;
  /* border-color: #c8e6c9; */
  color: #1b5e20;
}