/* Wrapper immagini */

.wrapperImmagini {
  background-color: var(--blue-grey-darken-2);
  display: grid;
  grid-template-rows: 300px auto;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
  gap: 10px;
}

.immagineBGBlur {
  aspect-ratio: 1 / 1;
  display: block;
  filter: blur(3px);
  height: 100%;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.immagineBG {
  aspect-ratio: 1 / 1;
  display: block;
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

.immagineCorrente {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 2;
}

.caroselloImmagini {
  border-radius: 6px;
  display: grid;
  grid-auto-flow: column;
  height: 50px;
  justify-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 2;
  justify-content: center;
  gap: 10px;
}

.caroselloImmagini>img {
  aspect-ratio: 1 / 1;
  height: inherit;
  object-fit: cover;
  opacity: 0.7;
  border-radius: 6px;
}

.caroselloImmagini>img.active {
  opacity: 1;
}

/**/

.infoPrincipali {
  display: grid;
  gap: 10px;
}

.infoPrincipali .titolo {
  font-size: 20px;
  font-weight: 700;
}

.infoPrincipali .prezzo {
  font-size: 18px;
  font-weight: 600;
}

.infoPrincipali .descrizione {
  font-size: 14px;
  color: #616161;
}

.azioniPrincipali {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: fit-content;
}

.infoSecondarie {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 10px 0;
}

.infoSecondarie>span:nth-child(odd) {
  font-size: 16px;
  font-weight: 500;
}

.infoSecondarie>span:nth-child(even) {
  font-size: 16px;
}

/* 
.infoUtente {
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: center;
  gap: 14px 10px;
  margin-bottom: 30px;
}

.infoUtente .titolo {
  grid-column: span 2;
  font-size: 24px;
}

.infoUtente>.icon {
  color: var(--raisin-black);
}

.infoUtente>span:not(.titolo) {
  font-size: 18px;
  color: var(--raisin-black);
}

.infoUtente>.utenteButton {
  grid-column: span 2;
  width: auto;
  justify-content: start;
  margin: 0 auto 0 0;
} */