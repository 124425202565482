.footer {
  /* background-color: var(--blue-grey-lighten-4); */
  margin-top: 50px;
}

.footer>.footerContent {
  display: grid;
  gap: 5px;
  padding: 20px 20px 90px;
  justify-items: center;
}

.footer>.footerContent>.logo {
  width: 120px;
  margin-bottom: 5px;
}

.footer>.footerContent>.logo path {
  fill: var(--blue-grey-darken-2);
}

.footer>.footerContent>div {
  display: grid;
  /* grid-template-columns: 20px 1fr; */
  align-items: center;
  gap: 10px;
}

.footer>.footerContent>div>span {
  font-size: 11px;
  line-height: 15px;
  font-weight: 500;
  color: var(--blue-grey);
}