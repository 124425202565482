.searchWrapper {
  position: relative;
}

.searchWrapper>.searchIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  color: var(--blue-grey-darken-1);
}

.searchWrapper>.searchInput {
  border: none;
  outline: none;
  user-select: none;
  -webkit-appearance: none;
  /**/
  /* background-color: var(--blue-grey-lighten-5);
  background-color: #f3f6fc; */
  /* background: linear-gradient(0deg, rgba(105, 145, 214, .08), rgba(105, 145, 214, .08)); */
  /* background-color: #edf2fa; */
  background-color: #f3f7fc;
  width: 100%;
  padding: 14px 12px;
  padding-left: 42px;
  border-radius: 24px;
  font-size: 16px;
  line-height: 20px;
}

.searchWrapper>.searchInput::placeholder {
  color: var(--blue-grey-darken-4);
}