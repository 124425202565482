.tableCategorie {
  display: grid;
  grid-template-columns: auto repeat(2, 1fr) auto;
  gap: 15px 10px;
  align-items: center;
  /* border: 1px solid var(--blue-grey-lighten-3);
  border-radius: 8px;
  padding: 4px; */
}

.tableCategorie>span>span {
  display: block;
}

.tableCategorie>.divider,
.tableCategorie>.btnCrea {
  grid-column: 1 / 5;
}

.tableCategorie>.divider {
  border-bottom: 1px solid var(--blue-grey-lighten-4);
}

/**/

.tablePrezzi {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  align-items: center;
  border: 1px solid var(--blue-grey-lighten-3);
  border-radius: 8px;
  padding: 4px;
  background-color: #f5f5f5;
}

.tablePrezzi>label {
  color: var(--blue-grey-darken-2);
  font-size: 16px;
  font-weight: 400;
}