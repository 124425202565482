.card {
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--blue-grey-lighten-3);
  box-sizing: border-box;
  display: inline-grid;
  padding: 16px;
  grid-template-columns: 1fr auto;
  gap: 10px;
  width: 100%;
}

.card.admin {
  background-color: white;
  border-color: #ff9800;
}

.card>span {
  grid-column: 1;
  font-size: 18px;
  font-weight: 500;
}

.card>p {
  grid-column: 1;
  font-size: 15px;
  margin:0;
}

.card>div:nth-child(3) {
  grid-row: 1/3;
  grid-column: 2;
  align-self: center;
  background-color: var(--blue-grey-lighten-5);
  color: var(--blue-grey-darken-2);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}